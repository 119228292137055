<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">市政规划二所</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <b><span style="font-size:16px;">市政规划二所主要职责：</span></b>
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">主要承担衔接新的国土空间规划体系，编制各类市政专项规划和选址论证工作，包括水资源、能源、通信、韧性城市、环境整治、综合防灾等，以及各级国土空间规划、土地开发利用前期项目可研等市政部分内容；编写各类城市规划的环境影响的篇章或者说明；城市市政公用设施相关的咨询和服务等。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;"><b>副所长：王中利（负责人）、王泽正</b></span> 
        </p>
        <img width="80%" style="margin: 10px 10% 0" src="../../assets/htmlimg/“市政规划二所”大.jpeg" alt="" />
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>